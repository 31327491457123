
/*
* ----------------------------------------------------------------------------------------
*  START Roadmap STYLE
* ----------------------------------------------------------------------------------------
*/
.HomeDemo1 .timeline-split{
    position: relative;
    width: 100%
}
.HomeDemo1 .timeline-split .timeline {
   position: relative;
   padding: 50px;
   overflow: hidden
}
.HomeDemo1 .timeline-split .timeline h3{
    font-size: 22px;
    color: #25cbd3
}
.HomeDemo1 .timeline-split .timeline span{
    color: #666;
    display: block;
    margin-bottom: 10px
}
/*top circle */
.HomeDemo1 .timeline-split .timeline::before {
   content: "";
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: block;
   position: absolute;
   top: 0;
   left: 50%;
   background: #378CE7;
   left: calc(50% - 10px);
}
/*vertival line */
.HomeDemo1 .timeline-split .timeline::after {
   content: "";
   width: 2px;
   height: 4000px;
   display: block;
   position: absolute;
   top: 10px;
   left: 50%;
   left: calc(50% - 1px);
   background: #378CE7;
}

/*timeline block */
.HomeDemo1 .timeline-split .timeline .block {
   width: 50%;
    padding: 30px;
    background-color: #378CE7;
    border: 1px solid #67C6E3;
    width: calc(50% - 74px);
    border-radius: 15px;
    text-align: left;
    position: relative;
}
.HomeDemo1 .timeline .block .date{
    padding: 5px 10px;
    display: inline-block;
    background: #378CE7;
    margin: 10px 0;
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
}
.HomeDemo1 .timeline .block .between{
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
}
/*block marker */
.HomeDemo1 .timeline-split .timeline .block::before {
   content: "";
   width: 10px;
   height: 10px;
   border-radius: 50%;
   position: absolute;
   background: #378CE7;
   top: 30px;
}
.timeline-split .timeline .block.block-left::after,
.HomeDemo1 .timeline-split .timeline .block.block-right::after {
   content: "";
    width: 95px;
    height: 2px;
    position: absolute;
    background: #378CE7;
    top: 34px;
    z-index: -1;
    right: -78px;
}
.HomeDemo1 .timeline-split .timeline .block.block-right::after{
    left: -80px
}
/*left block */
.HomeDemo1 .timeline-split .timeline .block.block-left {
   text-align: right;
}
.HomeDemo1 .timeline-split .timeline .block.block-left::before {
   right: -80px;
}
.HomeDemo1 .timeline .block p{
    margin-bottom: 0
}
/*right block */
.HomeDemo1 .timeline-split .timeline .block.block-right {
   text-align: left;
   margin-left: 50%;
   margin-left: calc(50% + 74px);
}
.HomeDemo1 .timeline-split .timeline .block.block-right::before {
   left: -80px;
}
/*decrease the timeline heading text */
@media (max-width: 992px) {
   .HomeDemo1 .timeline-split .timeline {
      padding: 50px 20px;
   }

   .HomeDemo1 .timeline-split .timeline h3 {
      font-size: 19px;
   }
}
/*change timeline layout to fit tiny screen size */
@media (max-width: 992px) {
    .HomeDemo1 .timeline-split .timeline .block.block-left::after {
        left: -80px;
    }
   .HomeDemo1 .timeline-split .timeline::after {
      left: 9px;
   }
   .HomeDemo1 .timeline-split .timeline::before {
      left: 0;
   }
   .HomeDemo1 .timeline-split .timeline .circle {
      left: 2px;
   }
   .HomeDemo1 .timeline-split .timeline .block {
      width: 100% !important;
      text-align: left;
      padding-left: 20px;
   }
   .HomeDemo1 .timeline-split .timeline .block::before {
      left: -15px;
   }
   .HomeDemo1 .timeline-split .timeline .block.block-left {
      text-align: left;
   }
   .HomeDemo1 .timeline-split .timeline .block.block-right {
      margin-left: 0;
   }
   .HomeDemo1 .timeline-split .timeline .block.block-right::before {
      left: -15px;
   }
   .HomeDemo1 .mt-30{
    margin-top: 30px
   }
}
